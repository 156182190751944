import { HiringProcessInterface, HiringStageType } from '@src/interfaces/hiringProccess'
import { HiringStageHistoryState } from './types'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

export const getBackUrl = (
  type: HiringStageType,
  locationState?: HiringStageHistoryState,
  hiringProcess?: HiringProcessInterface,
) => {
  if (locationState?.hiringProcessId) {
    return pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.HIRING_STAGES, {
      id: locationState?.isCreatingJobPosting ? undefined : locationState.jobPostingId,
    })
  }
  switch (type) {
    case 'specialisation':
      return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
        id: hiringProcess?.specialisation?.id,
      })
    case 'role':
      return pathToUrl(ROUTES.FORMS.ROLE.HIRING_PROCESS, {
        id: hiringProcess?.specialisation?.id,
      })
    case 'general':
    default:
      return pathToUrl(ROUTES.RECRUITMENT.HIRING_STAGES)
  }
}
