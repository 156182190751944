import React, { useState } from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import { Button, Checkbox, Item, Side, VStack } from '@revolut/ui-kit'
import {
  addHiringStagesToSpecialisationHiringProcess,
  useGetHiringStages,
} from '@src/api/hiringProcess'
import PageLoading from '@src/components/PageLoading/PageLoading'
import HiringStageDetails from '@src/components/HiringStages/HiringStageDetails'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import omit from 'lodash/omit'

type AddFromHiringStagesBankProps = {
  hiringProcess: SpecialisationHiringProcess
  onSubmit: (hiringProcess: HiringProcessInterface[]) => void
  onClose: () => void
}

const AddFromHiringStagesBank = ({
  hiringProcess,
  onSubmit,
  onClose,
}: AddFromHiringStagesBankProps) => {
  const { data, isLoading } = useGetHiringStages()
  const hiringStages = data?.results ?? []
  const [selectedStageIds, setSelectedStageIds] = useState<number[]>([])
  const [loading, setLoading] = useState(false)
  const handleAddHiringStages = async () => {
    setLoading(true)
    try {
      if (selectedStageIds) {
        const promises = await Promise.all(
          selectedStageIds
            .map(id => hiringStages.find(hiringStage => hiringStage.id === id)!)
            .map(hiringStage =>
              addHiringStagesToSpecialisationHiringProcess(
                omit(
                  {
                    ...hiringStage,
                    specialisation: hiringProcess.specialisation,
                    hiring_process: hiringProcess,
                  },
                  'id',
                ),
              ),
            ),
        )
        const results = promises.map(res => res.data)
        onSubmit(results)
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <SideBar isOpen variant="wide" title="Hiring stages Bank" onClose={onClose}>
      {isLoading ? (
        <PageLoading />
      ) : (
        <VStack gap="s-8">
          {data?.results?.map(hiringStage => {
            const selected = selectedStageIds.includes(hiringStage.id)
            return (
              <Item key={hiringStage.id} use="label">
                <Item.Prefix>
                  <Checkbox
                    disabled={loading}
                    checked={selected}
                    onClick={() => {
                      if (!loading) {
                        setSelectedStageIds(
                          selected
                            ? selectedStageIds.filter(id => id !== hiringStage.id)
                            : [...selectedStageIds, hiringStage.id],
                        )
                      }
                    }}
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>{hiringStage.title}</Item.Title>
                  <Item.Description>
                    <HiringStageDetails hiringStage={hiringStage} />
                  </Item.Description>
                </Item.Content>
              </Item>
            )
          })}
        </VStack>
      )}
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleAddHiringStages}
          pending={loading}
          disabled={loading || !selectedStageIds.length}
        >
          Add stage
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

export default AddFromHiringStagesBank
