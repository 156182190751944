import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { Link } from 'react-router-dom'
import {
  Box,
  Ellipsis,
  Flex,
  Link as UILink,
  Text,
  Action,
  Tag,
  Token,
  IconButton,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import Employee from '@components/ColumnInserts/Employee/Employee'
import { MultipleEmployees } from '@components/ColumnInserts/MultipleEmployees/MultipleEmployees'
import { Grid } from '@components/CommonSC/Grid'
import { LockOutline } from '@revolut/icons'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import HiringStageTypeTag from '@src/components/HiringStages/HiringStageTypeTag'

export const hiringProcessTitleColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: 'title',
  selectorsKey: selectorKeys.hiring_stages,
  title: 'Title',
}

export const hiringProcessTitleWithLockColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: 'title',
  selectorsKey: selectorKeys.hiring_stages,
  insert: ({ data }) => {
    return (
      <Flex>
        <Ellipsis>{data.title}</Ellipsis>
        {data?.is_company_standard_round && (
          <Box ml="s-8">
            <LockOutline color="grey-tone-20" size={14} />
          </Box>
        )}
      </Flex>
    )
  },
  title: 'Title',
}

export const hiringProcessTypeColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.text,
  idPoint: 'stage_type.id',
  dataPoint: 'stage_type.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage type',
}

export const hiringProcessTitleWithLockSpecialisationsColumn: ColumnInterface<HiringProcessInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'title',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => {
      return (
        <Flex>
          <Ellipsis>{data.title}</Ellipsis>
          {(data?.is_company_standard_round || data?.limit_editing_in_specialisation) && (
            <Box ml="s-8">
              <LockOutline color="grey-tone-20" size={14} />
            </Box>
          )}
        </Flex>
      )
    },
    title: 'Title',
  }

export const hiringProcessOwnerColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  notHoverable: true,
  insert: ({ data }) => (
    <Employee
      id={data.owner?.id}
      name={data?.owner?.full_name}
      avatar={data.owner?.avatar!}
    />
  ),
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
}

export const hiringProcessDurationColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'duration',
  dataPoint: 'duration',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.duration &&
    data.duration_unit && (
      <Text color="grey-tone-50">
        {getDuration(data.duration, data.duration_unit.name)}
      </Text>
    ),
  title: 'Duration',
}

export const hiringProcessPlaybookColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.link,
  idPoint: 'playbook_link',
  dataPoint: 'playbook_link',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Playbook',
}

export const hiringProcessScorecardLinkColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'scorecard_template',
  dataPoint: 'scorecard_template',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (!data.scorecard_template || !data?.scorecard_template?.name) {
      return '-'
    }

    return (
      <UILink
        use={Link}
        onClick={e => e.stopPropagation()}
        to={getLocationDescriptor(
          pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
            id: data.scorecard_template.id,
          }),
        )}
      >
        View
      </UILink>
    )
  },
  title: 'Scorecard',
}

export const hiringProcessScorecardPreviewColumn = (
  onPreview: (data: HiringProcessInterface) => void,
): ColumnInterface<HiringProcessInterface> => ({
  type: CellTypes.insert,
  idPoint: 'scorecard_template',
  dataPoint: 'scorecard_template',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (!data.scorecard_template || !data?.scorecard_template?.name) {
      return '-'
    }

    return (
      <Action
        onClick={() => {
          onPreview(data)
        }}
      >
        View
      </Action>
    )
  },
  title: 'Scorecard',
})

export const hiringProcessEligibleInterviewsLinkColumn: ColumnInterface<HiringProcessInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'interviewers',
    dataPoint: 'interviewers',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => {
      if (data.interviewers_groups?.length) {
        return (
          <Grid flow="column" justifyContent="start" alignItems="center">
            {data.interviewers_groups.map(group => (
              <Text
                key={group.id}
                borderRadius="8px"
                use="div"
                py="s-2"
                px="s-8"
                bg="grey-tone-10"
              >
                {group.name}
              </Text>
            ))}
          </Grid>
        )
      }
      return <MultipleEmployees maxEmployees={2} employees={data.interviewers} />
    },
    title: 'Eligible interviewers',
  }

export const hiringProcessSeniorityColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority_min',
  dataPoint: 'seniority_min',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data?.is_limited_by_seniorities) {
      if (data?.seniority_min?.name === data?.seniority_max?.name) {
        return <Text>{data?.seniority_min?.name}</Text>
      }
      return (
        <Text>
          {data?.seniority_min?.name} - {data?.seniority_max?.name}
        </Text>
      )
    }

    return 'All'
  },
  title: 'Seniorities',
}

export const hiringProcessSkillsColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'skills',
  dataPoint: 'skills',
  sortKey: 'skills',
  filterKey: selectorKeys.skillset,
  insert: ({ data }) => {
    if (data.skills?.length) {
      return data.skills.map(skill => skill.name).join(',')
    }
    return '-'
  },
  selectorsKey: selectorKeys.none,
  title: 'Skills',
}

export const hiringProcessNoteColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.text,
  idPoint: 'notes',
  dataPoint: 'notes',
  sortKey: 'notes',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Note',
}

export const hiringProcessActionColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  dataPoint: 'action',
  idPoint: 'action',
  sortKey: null,
  notHoverable: true,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
}

export const hiringProcessStageTitleColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data, parentIndexes }) => {
    return (
      <Flex>
        <Ellipsis>
          {parentIndexes[0] + 1}. {data.title}
        </Ellipsis>
      </Flex>
    )
  },
  title: 'Stage',
}

export const hiringProcessStageTypeColumn: ColumnInterface<HiringProcessInterface> = {
  type: CellTypes.insert,
  idPoint: 'stage_type',
  dataPoint: 'stage_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <>
      {(data?.is_company_standard_round || data?.limit_editing_in_specialisation) && (
        <Tag color={Token.color.orange} useIcon="LockClosed" variant="outlined">
          Required
        </Tag>
      )}
      <HiringStageTypeTag stageType={data.stage_type} />
    </>
  ),
  title: 'Stage',
}

export const hiringProcessStageInterviewersColumn: ColumnInterface<HiringProcessInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'interviewer_type',
    dataPoint: 'interviewer_type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => {
      if (data.interviewers_groups?.length) {
        return (
          <>
            {data.interviewers_groups.map(group => (
              <Tag
                key={group.id}
                bg={Token.color.greyTone10}
                color={Token.color.greyTone50}
                useIcon="People"
                variant="faded"
              >
                {group.name}
              </Tag>
            ))}
          </>
        )
      }
      return <MultipleEmployees maxEmployees={2} employees={data.interviewers} />
    },
    title: 'Interviewers',
  }

export type HiringProcessStageActionHandler = (id: number) => void

export const createHiringProcessStageActionsColumn = (
  onEdit: HiringProcessStageActionHandler,
  onDelete: HiringProcessStageActionHandler,
): ColumnInterface<HiringProcessInterface> => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return (
      <Flex justifyContent="space-around">
        <IconButton
          color={Token.color.greyTone50}
          size={16}
          useIcon="Pencil"
          onClick={() => {
            onEdit(data.id)
          }}
        />
        <IconButton
          color={Token.color.greyTone50}
          size={16}
          useIcon="Delete"
          onClick={() => {
            onDelete(data.id)
          }}
        />
      </Flex>
    )
  },
  title: 'Actions',
})
