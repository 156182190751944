import React from 'react'
import { Portal } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import { formHiringProcessStagesSpecialisationRequest } from '@src/api/hiringProcess'
import HiringStagesSidebarPreview from '@src/features/HiringStageSidebarPreview/HiringStageSidebarPreview'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLocation, useParams } from 'react-router-dom'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'

type HiringRoundDetailsSidebarProps = {
  customising: boolean
  hiringProcess: SpecialisationHiringProcess
  hiringRoundId: number
  onClose: () => void
  onSuccessDelete?: () => void
}

export const HiringRoundDetailsSidebar = ({
  customising,
  hiringProcess,
  hiringRoundId,
  ...props
}: HiringRoundDetailsSidebarProps) => {
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  return (
    <Portal>
      <Form
        api={formHiringProcessStagesSpecialisationRequest}
        validator={{}}
        disableLocalStorageCaching
        forceParams={{ id: String(hiringRoundId) }}
      >
        <HiringStagesSidebarPreview
          {...props}
          type="specialisation"
          onEdit={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION, { id: hiringRoundId }),
              {
                ...locationState,
                specialisation: hiringProcess.specialisation,
                hiringProcessId: hiringProcess.id,
                customiseHiringProcess: customising ? hiringProcess : undefined,
                jobPostingId: params.id ?? locationState.jobPostingId,
                isCreatingJobPosting: !params.id,
              },
            )
          }}
        />
      </Form>
    </Portal>
  )
}
