import React, { useState } from 'react'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { HiringProcessSelector } from '@src/features/JobPostingFlow/HiringStages/HiringProcessSelector'
import { HiringProcessCustomise } from '@src/features/JobPostingFlow/HiringStages/HiringProcessCustomise'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { JobPostingLocationState } from '@src/features/JobPostingFlow/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  cloneSpecialisationHiringProcess,
  updateSpecialisationHiringProcess,
  useGetSpecialisationHiringProcess,
  useGetSpecialisationHiringProcessList,
} from '@src/api/hiringProcess'
import {
  ActionButton,
  Button,
  StatusPopup,
  Subheader,
  useStatusPopup,
} from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'

export const useHiringProcess = (
  specialisationId?: string | number,
  hiringProcessId?: number,
) => {
  const {
    data: list,
    isLoading: loadingList,
    refetch: refetchList,
  } = useGetSpecialisationHiringProcessList(specialisationId)
  const {
    data,
    isLoading: loadingData,
    refetch: refetchData,
  } = useGetSpecialisationHiringProcess(specialisationId, hiringProcessId)
  const options = list.map(specialisationHiringProcess => ({
    key: specialisationHiringProcess.id,
    label: specialisationHiringProcess.name,
    value: specialisationHiringProcess,
  }))
  return {
    data,
    list,
    options,
    loadingData,
    loadingList,
    refetchData,
    refetchList,
  }
}

export const HiringStages = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const history = useHistory()
  const [customiseHiringProcess, setCustomiseHiringProcess] = useState(false)
  const { data, list, options, loadingData, loadingList, refetchData, refetchList } =
    useHiringProcess(values.specialisation?.id, values.hiring_process?.id)
  const statusPopup = useStatusPopup()
  if (!customiseHiringProcess && locationState?.customiseHiringProcess) {
    values.hiring_process = locationState?.customiseHiringProcess
    setCustomiseHiringProcess(true)
    history.replace(history.location.pathname, {
      ...locationState,
      customiseHiringProcess: undefined,
    })
  }
  if (!values.hiring_process && list.length) {
    values.hiring_process =
      (locationState?.hiringProcessId
        ? list.find(({ id }) => id === locationState.hiringProcessId)
        : list.find(({ is_default }) => is_default)) ?? null
  }

  const handleSaveHiringProcess = async (hiringProcess: SpecialisationHiringProcess) => {
    try {
      await updateSpecialisationHiringProcess(
        hiringProcess.id,
        hiringProcess.specialisation.id,
        {
          is_draft: false,
        },
      )
      values.hiring_process = hiringProcess
      refetchList()
      refetchData()
      setCustomiseHiringProcess(false)
    } catch {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            There was an error updating hiring process
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button elevated onClick={statusPopup.hide}>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }
  const handleCustomiseProcess = async () => {
    try {
      if (data) {
        const cloneResponse = await cloneSpecialisationHiringProcess(
          data.specialisation.id,
          data.id,
          values.name,
        )
        const clonedHiringProcess = cloneResponse.data
        values.hiring_process = clonedHiringProcess
        setCustomiseHiringProcess(true)
      }
    } catch {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            There was an error creating hiring process
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button elevated onClick={statusPopup.hide}>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }
  const specialisationHeader =
    values.specialisation?.name && ` for ${values.specialisation.name} specialisation`
  if (customiseHiringProcess && data) {
    return (
      <HiringProcessCustomise
        hiringProcess={data}
        header={
          <Subheader variant="nested">
            <Subheader.Title>
              You can create a new hiring process and immediately use it for the job
              posting you’re creating. This hiring process will be saved
              {specialisationHeader} and can be used in other job postings as well
            </Subheader.Title>
          </Subheader>
        }
        onCancel={hiringProcess => {
          values.hiring_process = hiringProcess
          refetchList()
          refetchData()
          setCustomiseHiringProcess(false)
        }}
        onRefreshData={refetchData}
        onSave={handleSaveHiringProcess}
      />
    )
  }
  return (
    <HiringProcessSelector
      bottomActions={
        <PageActions>
          <Button
            use={InternalLink}
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
                locationState,
              )
            }}
            variant="secondary"
            elevated
          >
            Back
          </Button>
          <NewSaveButtonWithPopup<JobPostingInterface>
            useValidator
            noPopup
            hideWhenNoChanges={false}
            disabled={!data}
            onAfterSubmit={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                  id: values.id,
                  specId: values.specialisation?.id,
                }),
              )
            }}
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      }
      data={data}
      header={
        <>
          <Subheader>
            <Subheader.Title>Select hiring process</Subheader.Title>
          </Subheader>
          <Subheader variant="nested">
            <Subheader.Title>
              You can select any of the hiring processes{specialisationHeader} to be used
              for this job posting
            </Subheader.Title>
          </Subheader>
        </>
      }
      options={options}
      loadingData={loadingData}
      loadingList={loadingList}
      selectorActions={
        <ActionButton
          useIcon="Pencil"
          disabled={loadingList}
          onClick={() => {
            handleCustomiseProcess()
          }}
        >
          Create new
        </ActionButton>
      }
      onChangeHiringProcess={value => {
        values.hiring_process = value
      }}
      onRefreshData={refetchData}
    />
  )
}
