import React, { useState } from 'react'
import {
  Item,
  ItemSkeleton,
  SelectInput,
  SelectOptionItemType,
  Subheader,
} from '@revolut/ui-kit'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import HiringProcessDetails from '@src/components/HiringProcessDetails/HiringProcessDetails'
import { PageBody } from '@src/components/Page/PageBody'
import { HiringRoundDetailsSidebar } from '@src/features/JobPostingFlow/HiringStages/HiringRoundDetailsSidebar'

type HiringProcessSelectorProps = {
  bottomActions?: React.ReactNode
  data: SpecialisationHiringProcess | undefined
  header?: React.ReactNode
  options: SelectOptionItemType<SpecialisationHiringProcess>[]
  selectorActions?: React.ReactNode
  loadingData: boolean
  loadingList: boolean
  onChangeHiringProcess: (hiringProcess: SpecialisationHiringProcess) => void
  onRefreshData: () => void
}

export const HiringProcessSelector = ({
  bottomActions,
  data,
  header,
  options,
  selectorActions,
  loadingData,
  loadingList,
  onChangeHiringProcess,
  onRefreshData,
}: HiringProcessSelectorProps) => {
  const [showRoundDetails, setShowRoundDetails] = useState<HiringProcessInterface | null>(
    null,
  )
  if (loadingList) {
    return (
      <PageBody>
        <ItemSkeleton />
        <ItemSkeleton />
      </PageBody>
    )
  }
  return (
    <>
      {data && showRoundDetails && (
        <HiringRoundDetailsSidebar
          customising={false}
          hiringProcess={data}
          hiringRoundId={showRoundDetails.id}
          onSuccessDelete={() => {
            setShowRoundDetails(null)
            onRefreshData()
          }}
          onClose={() => {
            setShowRoundDetails(null)
          }}
        />
      )}
      <PageBody>
        {header}
        <Item>
          <Item.Content>
            <Item.Title>
              <SelectInput
                label="Hiring process"
                options={options}
                value={options?.find(option => data?.id === option.value.id)?.value}
                onChange={v => {
                  if (v) {
                    setShowRoundDetails(null)
                    onChangeHiringProcess(v)
                  }
                }}
              />
            </Item.Title>
          </Item.Content>
          <Item.Side>{selectorActions}</Item.Side>
        </Item>
        <Subheader>
          <Subheader.Title>Stages details</Subheader.Title>
        </Subheader>
        {loadingData ? (
          <ItemSkeleton />
        ) : (
          <HiringProcessDetails
            hiringProcessRounds={data?.hiring_process_stages ?? []}
            onShowRoundDetails={hiringProcessRound => {
              setShowRoundDetails(hiringProcessRound)
            }}
            loading={loadingData}
          />
        )}
      </PageBody>
      {bottomActions}
    </>
  )
}
