import React, { useEffect } from 'react'
import { Box, BoxProps, Flex, HeaderSkeleton, IconButton, Text } from '@revolut/ui-kit'
import { BackButtonArrow, Cross } from '@revolut/icons'
import { Link } from 'react-router-dom'
import { css } from 'styled-components'
import useResizeObserver from 'use-resize-observer'
import { getBackUrl, getLocationDescriptor } from '@src/actions/RouterActions'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { GlobalSearch } from '@src/features/MainHeader/GlobalSearch'
import { HelpCenter } from '@src/features/MainHeader/HelpCenter'
import { Settings } from '@src/features/MainHeader/Settings'
import { Warning } from '@src/features/MainHeader/Warning'
import { UserProfileLink } from '@src/features/MainHeader/UserProfileLink'
import Notifications from '@src/features/Notifications/Notifications'
import { PageHeaderTitle } from '@components/Page/Header/PageHeaderTitle'
import { useCurrentTenantInfo } from '@src/api/tenants'

export enum PageHeaderRouterAction {
  goBack = 'goBack',
  navigate = 'navigate',
}

export interface PageHeaderProps extends Omit<BoxProps, 'title'> {
  title: React.ReactNode
  subtitle?: React.ReactNode
  backUrl: string
  routerAction?: PageHeaderRouterAction
  children?: React.ReactNode | React.ReactNode[]
  isLoading?: boolean
  backUrlLocationState?: object
  onClickBack?: () => void
  hideGlobalSearch?: boolean
  renderAbove?: () => React.ReactNode
  renderAboveTitle?: () => React.ReactNode
  hideUpperBlock?: boolean
  noWrap?: boolean
}

const stickyCss = css`
  position: sticky;
  z-index: ${p => p.theme.zIndex.aboveMain + 2};
  top: 0;
`

const withDemoBarCss = css`
  top: 40px;
  margin-bottom: 24px;
`

const HeaderCss = css`
  background-color: ${p => p.theme.colors['layout-background']};
  ${stickyCss}
`

export const PageHeader = ({
  title,
  subtitle,
  backUrl,
  children,
  isLoading,
  routerAction = PageHeaderRouterAction.goBack,
  backUrlLocationState,
  onClickBack,
  hideGlobalSearch,
  hideUpperBlock,
  renderAbove,
  renderAboveTitle,
  noWrap = true,
  ...props
}: PageHeaderProps) => {
  const { height: headerHeight, ref } = useResizeObserver()

  const { isDemoMode } = useCurrentTenantInfo()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight + 26 + (isDemoMode ? 40 : 0)
    }
  }, [headerHeight, isDemoMode])

  const routesBack = routerAction === PageHeaderRouterAction.goBack

  return isLoading ? (
    <HeaderSkeleton variant="item" labelBackButton="Back" />
  ) : (
    <>
      <Box
        pt="s-20"
        pb="s-6"
        {...props}
        ref={ref}
        css={[HeaderCss, isDemoMode && withDemoBarCss]}
      >
        {renderAbove?.()}
        {!hideUpperBlock && (
          <Flex alignItems="center" height="40px">
            <IconButton
              size={24}
              useIcon={routesBack ? BackButtonArrow : Cross}
              aria-label="close"
              color="foreground"
              use={onClickBack ? undefined : Link}
              onClick={onClickBack}
              // @ts-expect-error object works fine here, but UI kit expects string
              to={
                routesBack
                  ? getBackUrl(backUrl, backUrlLocationState)
                  : getLocationDescriptor(backUrl, backUrlLocationState)
              }
            />

            {/* TODO: this will have to contain breadcrumbs and/or title */}
            <Flex flex={1} />

            {hideGlobalSearch ? null : (
              <Flex alignItems="center" gap="s-8">
                <GlobalSearch />
                <Notifications />
                <HelpCenter />
                <Settings />
                <Warning />
                <UserProfileLink />
              </Flex>
            )}
          </Flex>
        )}
      </Box>

      <Box>
        {renderAboveTitle?.()}
        <Text
          use="h1"
          variant="h1"
          whiteSpace={noWrap ? 'nowrap' : undefined}
          textOverflow="ellipsis"
          overflow="hidden"
          mb="s-4"
          data-testid="header_title"
        >
          {title}
        </Text>

        <Box>
          <Text
            mb="s-16"
            color="grey-tone-50"
            fontSize="14px"
            whiteSpace={noWrap ? 'nowrap' : undefined}
            textOverflow="ellipsis"
            overflow="hidden"
            lineHeight="22px"
            use="div"
            data-testid="header-subtitle"
          >
            {subtitle}
          </Text>
        </Box>
        {!isLoading && children}
      </Box>
    </>
  )
}

PageHeader.Title = PageHeaderTitle
