import React from 'react'
import { Delete } from '@revolut/icons'
import { HiringProcessInterface, HiringStageType } from '@src/interfaces/hiringProccess'
import {
  formHiringProcessStagesRequest,
  formHiringProcessStagesRoleRequest,
  formHiringProcessStagesSpecialisationRequest,
} from '@src/api/hiringProcess'
import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import AsyncButton from '@src/features/Form/Buttons/AsyncButton'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useLocation } from 'react-router-dom'
import { HiringStageHistoryState } from '@src/pages/Forms/HiringStage/types'
import { getBackUrl } from '@src/pages/Forms/HiringStage/utils'
import { Statuses } from '@src/interfaces'

type Props = {
  type: HiringStageType
  onSuccess?: () => void
}

const HiringStageDeleteAction = ({ type, onSuccess }: Props) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const { state: locationState } = useLocation<HiringStageHistoryState>()

  const onClick = async () => {
    let result
    let request
    switch (type) {
      case 'general': {
        request = formHiringProcessStagesRequest
        break
      }
      case 'role': {
        request = formHiringProcessStagesRoleRequest
        break
      }
      case 'specialisation': {
        request = formHiringProcessStagesSpecialisationRequest
        break
      }
    }

    if (request?.update) {
      result = await request.update(
        {
          status: Statuses.archived,
        },
        {
          id: String(values.id),
        },
      )
    }

    if (result?.status === 204) {
      onSuccess?.()
    }

    return result
  }

  const canDelete =
    type === 'general'
      ? values?.field_options?.permissions?.includes(PermissionTypes.DeleteHiringstage)
      : true

  return (
    <AsyncButton
      submit={onClick}
      variant="negative"
      hide={!canDelete}
      size="sm"
      afterSubmitUrl={getBackUrl(type, locationState, values)}
      notification={{
        path: ROUTES.FORMS.HIRING_STAGES.GENERAL,
        updateMsg: 'Hiring stage successfully deleted.',
      }}
      useIcon={Delete}
    >
      Delete
    </AsyncButton>
  )
}

export default HiringStageDeleteAction
