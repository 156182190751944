import React, { useState } from 'react'
import {
  ActionButton,
  Avatar,
  Button,
  InputGroup,
  Item,
  StatusPopup,
  Subheader,
  VStack,
  useStatusPopup,
} from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import { PageActions } from '@src/components/Page/PageActions'
import { pathToUrl } from '@src/utils/router'
import { useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateTo } from '@src/actions/RouterActions'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { patchJobDescriptionOfRequisition } from '@src/api/requisitions'
import JobPostingPopup from '@src/features/JobPostingFlow/components/JobPostingPopup'
import { getBackUrl } from '@src/features/JobPostingFlow/utils'

const getPlaceholder = (title: string) => {
  switch (title) {
    case 'About the role':
      return 'What the function does'
    case 'What you’ll be doing':
      return 'The main goals and mission of this role (specialisation)'
    case "What you'll need":
      return 'The minimum requirements expected from the candidate'
    case 'Nice to have':
      return 'Any additional requirements (optional)'
    default:
      return ''
  }
}

type DescriptionProps = {
  onAfterSubmit: () => void
}

export const Description = ({ onAfterSubmit }: DescriptionProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const [openJobPostings, setOpenJobPostings] = useState(false)
  const statusPopup = useStatusPopup()
  const handleRequisitionConnectionError = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Cannot connect job posting to requisition(s)
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button elevated onClick={statusPopup.hide}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  return (
    <>
      {openJobPostings && (
        <JobPostingPopup
          confirmLabel="Reuse posting"
          specialisationId={values?.specialisation?.id!}
          onClose={() => {
            setOpenJobPostings(false)
          }}
          onConfirm={jobPosting => {
            values.name = jobPosting.name
            values.sections = jobPosting.sections
          }}
        />
      )}
      <PageBody>
        <VStack gap="s-16">
          <Item
            use="label"
            onClick={() => {
              setOpenJobPostings(true)
            }}
          >
            <Item.Avatar>
              <Avatar useIcon="WizardHat" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Reuse existing jobs</Item.Title>
              <Item.Description>
                Reuse content from previously posted job postings
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <ActionButton useIcon="Search">Browse job postings</ActionButton>
            </Item.Side>
          </Item>
          <LapeNewInput
            name="name"
            label="Job posting title"
            message="This is job posting title candidates will see on the career website (Specialisation name by default"
            required
          />
          {values.sections?.map((section, idx) => (
            <React.Fragment key={idx}>
              <Subheader>
                <Subheader.Title>{section.title}</Subheader.Title>
              </Subheader>
              <InputGroup>
                <LapeHTMLEditor
                  name={`sections.${idx}.content`}
                  placeholder={getPlaceholder(section.title)}
                  height={350}
                  required
                  addMarginToParagraphs
                />
              </InputGroup>
            </React.Fragment>
          ))}
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          noPopup
          hideWhenNoChanges={false}
          onAfterSubmit={async resp => {
            onAfterSubmit()
            if (params.id) {
              navigateTo(getBackUrl(params, locationState))
              return
            }
            let hasError = false
            if (locationState?.requisitionId) {
              try {
                await patchJobDescriptionOfRequisition(locationState?.requisitionId, {
                  job_posting: {
                    id: resp.id,
                  },
                })
              } catch {
                hasError = true
                handleRequisitionConnectionError()
              }
            }
            if (!hasError) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
                {
                  ...locationState,
                  jobPostingId: resp.id,
                },
              )
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
