import React, { useCallback, useState } from 'react'
import {
  ActionButton,
  ActionMenu,
  Button,
  Input,
  StatusPopup,
  Subheader,
  VStack,
  Widget,
  useDropdown,
  useStatusPopup,
} from '@revolut/ui-kit'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import { PageBody } from '@src/components/Page/PageBody'
import HiringProcessDetails from '@src/components/HiringProcessDetails/HiringProcessDetails'
import { PageActions } from '@src/components/Page/PageActions'
import AddFromHiringStagesBank from '@src/features/JobPostingFlow/HiringStages/AddFromHiringStagesBank'
import {
  specialisationArchiveHiringProcessRound,
  updateSpecialisationHiringProcess,
} from '@src/api/hiringProcess'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useLocation, useParams } from 'react-router-dom'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import debounce from 'lodash/debounce'
import { HiringRoundDetailsSidebar } from '@src/features/JobPostingFlow/HiringStages/HiringRoundDetailsSidebar'

type AddHiringStageMenuProps = {
  hiringProcess: SpecialisationHiringProcess
  jobPostingId?: number
  openAddHiringStagesBank: boolean
  onOpenAddHiringStagesBank: (open: boolean) => void
  onAddHiringStage: () => void
}

const AddHiringStageMenu = ({
  hiringProcess,
  openAddHiringStagesBank,
  onOpenAddHiringStagesBank,
  onAddHiringStage,
}: AddHiringStageMenuProps) => {
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const dropdown = useDropdown()
  return (
    <>
      {openAddHiringStagesBank && (
        <AddFromHiringStagesBank
          hiringProcess={hiringProcess}
          onSubmit={() => {
            onAddHiringStage()
          }}
          onClose={() => {
            onOpenAddHiringStagesBank(false)
          }}
        />
      )}
      <ActionButton {...dropdown.getAnchorProps()} useIcon="Plus">
        Add hiring stage
      </ActionButton>
      <ActionMenu {...dropdown.getTargetProps()}>
        <ActionMenu.Group>
          <ActionMenu.Item
            useIcon="Library"
            onClick={() => {
              onOpenAddHiringStagesBank(true)
            }}
          >
            <ActionMenu.Title>Add from Hiring Stages bank</ActionMenu.Title>
            <ActionMenu.Description>
              Add one or multiple stages from your company library
            </ActionMenu.Description>
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="Plus"
            onClick={() => {
              navigateTo(pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION, {}), {
                ...locationState,
                specialisation: hiringProcess.specialisation,
                hiringProcessId: hiringProcess.id,
                customiseHiringProcess: hiringProcess,
                jobPostingId: params.id ?? locationState.jobPostingId,
                isCreatingJobPosting: !params.id,
              })
            }}
          >
            <ActionMenu.Title>Create new stage</ActionMenu.Title>
            <ActionMenu.Description>Create new stage from scratch</ActionMenu.Description>
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

type HiringProcessCustomiseProps = {
  hiringProcess: SpecialisationHiringProcess
  header?: React.ReactNode
  onCancel: (hiringProcess: SpecialisationHiringProcess) => void
  onRefreshData: () => void
  onSave: (hiringProcess: SpecialisationHiringProcess) => void
}

export const HiringProcessCustomise = ({
  hiringProcess,
  header,
  onCancel,
  onRefreshData,
  onSave,
}: HiringProcessCustomiseProps) => {
  const [state, setState] = useState(hiringProcess)
  const [name, setName] = useState(hiringProcess.name)
  const [showRoundDetails, setShowRoundDetails] = useState<HiringProcessInterface | null>(
    null,
  )
  const [openAddHiringStagesBank, setOpenHiringStagesBank] = useState(false)
  const statusPopup = useStatusPopup()
  const handleNameChange = useCallback(
    debounce(async (newName: string) => {
      try {
        await updateSpecialisationHiringProcess(
          hiringProcess.id,
          hiringProcess.specialisation?.id,
          {
            name: newName,
          },
        )
      } catch {
        statusPopup.show(
          <StatusPopup variant="error" onClose={statusPopup.hide}>
            <StatusPopup.Title>
              There was an error updating hiring process name
            </StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }, 500),
    [],
  )
  const handleDeleteHiringStage = async (hiringProcessRound: HiringProcessInterface) => {
    const stages = state.hiring_process_stages
    try {
      setState({
        ...state,
        hiring_process_stages: stages?.filter(({ id }) => id !== hiringProcessRound.id),
      })
      await specialisationArchiveHiringProcessRound(hiringProcessRound.id)
      onRefreshData()
    } catch {
      setState({
        ...state,
        hiring_process_stages: stages,
      })
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error deleting hiring process rounds
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  const handleOrderHiringStages = async (
    hiringProcessStages: HiringProcessInterface[],
  ) => {
    const stages = state.hiring_process_stages
    try {
      setState({
        ...state,
        hiring_process_stages: hiringProcessStages,
      })
      await updateSpecialisationHiringProcess(
        hiringProcess.id,
        hiringProcess.specialisation?.id,
        {
          hiring_process_stages: hiringProcessStages,
        },
      )
      onRefreshData()
    } catch {
      setState({
        ...state,
        hiring_process_stages: stages,
      })
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error ordering hiring process rounds
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  return (
    <>
      {showRoundDetails && (
        <HiringRoundDetailsSidebar
          customising
          hiringProcess={hiringProcess}
          hiringRoundId={showRoundDetails.id}
          onSuccessDelete={() => {
            setShowRoundDetails(null)
            onRefreshData()
          }}
          onClose={() => {
            setShowRoundDetails(null)
          }}
        />
      )}
      <PageBody>
        {header}
        <Widget p="s-16">
          <VStack gap="s-16">
            <Input
              label="Name"
              value={name}
              onChange={event => {
                const currentName = event.currentTarget.value
                setName(currentName)
                handleNameChange(currentName)
              }}
              required
              aria-invalid={!name.trim().length}
              errorMessage={!name.trim().length && 'This field may not be blank.'}
            />
            <AddHiringStageMenu
              hiringProcess={hiringProcess}
              openAddHiringStagesBank={openAddHiringStagesBank}
              onOpenAddHiringStagesBank={val => {
                setShowRoundDetails(null)
                setOpenHiringStagesBank(val)
              }}
              onAddHiringStage={() => {
                onRefreshData()
              }}
            />
          </VStack>
        </Widget>
        <Subheader>
          <Subheader.Title>Stages details</Subheader.Title>
        </Subheader>
        <HiringProcessDetails
          onDeleteRound={handleDeleteHiringStage}
          onOrderChange={handleOrderHiringStages}
          onShowRoundDetails={hiringProcessRound => {
            setOpenHiringStagesBank(false)
            setShowRoundDetails(hiringProcessRound)
          }}
          hiringProcessRounds={state.hiring_process_stages ?? []}
        />
      </PageBody>
      <PageActions>
        <Button
          variant="negative"
          onClick={() => {
            onCancel(hiringProcess)
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!name.trim().length}
          onClick={() => {
            onSave(hiringProcess)
          }}
        >
          Save custom stages
        </Button>
      </PageActions>
    </>
  )
}
